import http from '@/utils/http'

export default {
  scheduleMessage(
    user_id,
    template_id,
    text,
    delivery_date,
    room_id,
    executive_name
  ) {
    return new Promise((resolve, reject) => {
      const postData = { user_id, delivery_date, room_id, executive_name }
      if (template_id !== undefined) {
        postData.template_id = template_id
      }
      if (text !== undefined) {
        postData.text = text
      }
      http
        .post('scheduled-messages', postData)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
  getScheduledMessages(room_id) {
    return new Promise((resolve, reject) => {
      http
        .get(
          `scheduled-messages?attribute=room_id&value=${room_id}&sort=delivery_date,-`
        )
        .then(response => resolve(response.data.body.data))
        .catch(err => reject(err))
    })
  },
  deleteScheduledMessage(id) {
    return new Promise((resolve, reject) => {
      http
        .delete(`scheduled-messages/${id}`)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
