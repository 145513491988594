import http from '@/utils/http'

export default {
  getTemplates(customer_id, project_id = null) {
    return new Promise((resolve, reject) => {
      http
        .get(
          `templates?project_id=${project_id}&attribute=template_category.code,customer_id&value=A12,${customer_id}`
        )
        .then(response => {
          let res = response.data.body.data
          if (res) {
            res = res.filter(item => !item.action)
          }
          resolve({
            filtered: response.data.filtered,
            msg: response.data.msg,
            per_page: response.data.per_page,
            response: response.data.response,
            total: response.data.total,
            body: { data: res },
          })
        })
        .catch(err => reject(err))
    })
  },
  sendTemplate(template) {
    return new Promise((resolve, reject) => {
      http
        .post(`rooms/template`, template)
        .then(response => resolve(response.data))
        .catch(err => reject(err))
    })
  },
}
