<template>
  <div v-if="message.private" class="private-container">
    <div class="private">
      <div class="private-title">
        Nota privada
        <div class="private-date">
          {{
            time
              ? new Date(time).toLocaleString('es-ES', { timeStyle: 'short' })
              : new Date().toLocaleString('es-ES', { timeStyle: 'short' })
          }}
        </div>
      </div>
      <div class="private-author">
        {{ executive.user_name }}
      </div>
      <p class="private-content">
        {{ message.text }}
      </p>
    </div>
  </div>
  <div v-else :class="{ send: send, receive: !send }" class="message">
    <MessageImage
      v-if="message.message_type == 'image'"
      :message="message"
      @show-image="showImage"
    />
    <MessageSticker
      v-else-if="message.message_type == 'sticker'"
      :message="message"
      @show-image="showImage"
    />
    <MessageAudio
      v-else-if="message.message_type == 'audio'"
      :message="message"
    />
    <MessageVideo
      v-else-if="message.message_type == 'video'"
      :message="message"
    />
    <MessageDocument
      v-else-if="message.message_type == 'document'"
      :message="message"
    />
    <MessageTemplate
      v-else-if="message.message_type == 'template'"
      :message="message"
    />
    <MessageButton
      v-else-if="message.message_type == 'button'"
      :message="message"
    />
    <MessageContact
      v-else-if="message.message_type == 'contacts'"
      :message="message"
      @show-contacts-modal="showContactsModal"
    />
    <MessageLocation
      v-else-if="message.message_type == 'location'"
      :message="message"
    />
    <MessageText v-else :message="message" />
    <span
      >{{
        time
          ? new Date(time).toLocaleString('es-ES', { timeStyle: 'short' })
          : new Date().toLocaleString('es-ES', { timeStyle: 'short' })
      }}
      {{ message.error_display }}
      <font-awesome-icon
        v-if="getIcon != null"
        :icon="getIcon"
        :class="getClass"
        size="lg"
    /></span>
  </div>
</template>
<script>
import MessageText from './Messages/Text.vue'
import MessageImage from './Messages/Image.vue'
import MessageSticker from './Messages/Sticker.vue'
import MessageAudio from './Messages/Audio.vue'
import MessageVideo from './Messages/Video.vue'
import MessageDocument from './Messages/Document.vue'
import MessageTemplate from './Messages/Template.vue'
import MessageButton from './Messages/Button.vue'
import MessageContact from './Messages/Contact.vue'
import MessageLocation from './Messages/Location.vue'

export default {
  name: 'MessageViewBody',
  components: {
    MessageText,
    MessageImage,
    MessageSticker,
    MessageAudio,
    MessageVideo,
    MessageDocument,
    MessageTemplate,
    MessageButton,
    MessageContact,
    MessageLocation,
  },
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
    time: { type: String, default: null },
    send: { type: Boolean, default: null },
  },
  data() {
    return {
      icon: 'fa-check fa-check',
      class: '',
    }
  },
  computed: {
    getIcon() {
      switch (this.message.state) {
        case 'failed':
          return 'fa-solid fa-circle-exclamation'
        case 'sent':
          return 'fa-solid fa-check'
        case 'delivered':
          return 'fa-solid fa-check-double'
        case 'read':
          return 'fa-solid fa-check-double'
      }
      return null
    },
    getClass() {
      switch (this.message.state) {
        case 'failed':
          return 'status-failed'
        case 'read':
          return 'status-read'
      }
      return null
    },
    executive() {
      let response = {}
      if (this.message.user_name) {
        response.user_name = this.message.user_name
        if (this.message.user_avatar) {
          response.user_avatar = this.message.user_avatar
        }
      } else {
        response = {
          user_name: 'Ejecutivo Virtual',
          user_avatar:
            'http://s3.amazonaws.com/cdn.mobysuite.com/extras/virtual-avatar.png',
        }
      }
      return response
    },
  },
  methods: {
    showImage(url) {
      this.$emit('show-image', url)
    },
    showContactsModal(contacts) {
      this.$emit('show-contacts-modal', contacts)
    },
  },
}
</script>

<style>
.message {
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 1px 0.5px 0px;
  background: #202c33;
  margin: 5px 100px;
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
  color: white;
}
.message.send {
  background: #95b2aa;
  align-self: flex-end;
  color: white;
}
.message span {
  margin-left: 25px;
  font-size: 11px;
  color: rgb(201, 201, 201);
}
.status-failed {
  color: rgb(220, 6, 6);
}
.status-read {
  color: #53bdeb;
}
</style>
