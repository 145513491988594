<template>
  <div
    :class="{
      app: true,
      dark: darkMode,
      light: !darkMode,
      nonSelectedContact: !clientPhone,
    }"
  >
    <audio
      v-if="false"
      ref="audioPlayer"
      src="https://cdn.mobysuite.com/extras/sounds/message-incoming.mp4"
      style="display: none;"
    ></audio>
    <div v-if="!clientPhone" class="sideBar">
      <SideBarHeader />
      <div class="sideBar__body">
        <div class="blankSpace"></div>
        <SideBar
          v-for="(room, key) in rooms"
          :key="room.slug"
          :active-message="key == activeIndex"
          :user-photo="room.client_photo_url"
          :user-name="room.client_phone"
          :last-message="
            room.messages.last_messages.slice(-1)[0]
              ? room.messages.last_messages.slice(-1)[0].text
              : ''
          "
          :last-message-time="
            room.messages.last_messages.slice(-1)[0]
              ? room.messages.last_messages.slice(-1)[0].created_at
              : ''
          "
          @click.native="changeRoom(key)"
        />
      </div>
    </div>
    <div
      v-if="rooms"
      :class="{ messageView: true, 'p-absolute': clientPhone }"
      style="position: relative;"
    >
      <div
        class="messageView__body"
        :class="{ 'extra-margin': showFastResponse }"
      >
        <div class="files-preview">
          <div v-for="(image, k) in images" :key="'image' + k" class="image">
            <img :src="setImage(image)" class="inner-image" />
            <div class="remove-image" @click="removeFile(false, k)">
              <font-awesome-icon
                :icon="['fas', 'trash-alt']"
                class="trash-icon"
              />
            </div>
          </div>
          <div v-for="(file, k) in files" :key="'file' + k" class="image">
            <div class="inner-file">
              {{ file.name.split('.')[file.name.split('.').length - 1] }}
            </div>
            <div class="remove-image" @click="removeFile(true, k)">
              <font-awesome-icon
                :icon="['fas', 'trash-alt']"
                class="trash-icon"
              />
            </div>
          </div>
        </div>
        <div
          v-if="rooms[activeIndex]"
          ref="messageBottom"
          class="data whatsapp-data-message"
        >
          <MessageViewBody
            v-for="(message, index_) in messages"
            :key="index_"
            :message="message"
            :send="message.client_user"
            :time="message.created_at"
            :next-time="
              messages[index_ + 1] ? messages[index_ + 1].created_at : null
            "
            @show-image="showImage"
            @show-contacts-modal="showContactModal"
            @handle-scroll="handleScroll"
          />
          <div v-if="showLoading" class="info-message">
            <div class="info-message-body">
              Cargando mensajes...
            </div>
          </div>
          <div v-else class="info-message">
            <div class="info-message-body">
              Has llegado al comienzo de tu comunicación con este cliente.
            </div>
          </div>
        </div>
      </div>
      <audio v-if="audioURL" controls>
        <source :src="audioURL" />
      </audio>
      <div
        class="messageView__footer"
        :class="{ 'private-message': privateOrPublicMessage }"
      >
        <template v-if="!chatbotActive">
          <div
            v-if="
              (sessionMinutesRemaining > 0 || sessionHoursRemaining > 0) &&
                sessionHoursRemaining <= 10
            "
            class="session-hours-remain-container"
          >
            <div class="session-hours-remain-icon">
              <font-awesome-icon :icon="['fas', 'clock']" />
            </div>
            <div class="session-hours-remain-message">
              {{
                sessionHoursRemaining > 0
                  ? sessionHoursRemaining > 1
                    ? 'Quedan'
                    : 'Queda'
                  : sessionMinutesRemaining > 1
                  ? 'Quedan'
                  : 'Queda'
              }}
              <span class="text-bold">
                {{
                  sessionHoursRemaining > 0
                    ? sessionHoursRemaining
                    : sessionMinutesRemaining
                }}
                {{
                  sessionHoursRemaining > 0
                    ? sessionHoursRemaining > 1
                      ? 'horas'
                      : 'hora'
                    : sessionMinutesRemaining > 1
                    ? 'minutos'
                    : 'minuto'
                }}
              </span>
              en esta sesión de WhatsApp. Después de eso solo podrás enviar
              plantillas.
            </div>
          </div>
        </template>
        <div class="messageView__footer-content">
          <ul class="footer-btns">
            <li
              :class="{
                active: showProgram,
                disabled: scheduleMessageButtonBlocked || chatbotActive,
              }"
              @click="
                scheduleMessageButtonBlocked || chatbotActive
                  ? null
                  : actionButtons('showProgram')
              "
            >
              <font-awesome-icon
                :icon="['fas', 'clock']"
                style="margin-right: 10px"
              />
              Programar mensaje
            </li>
            <li
              :class="{
                active: showFastResponse,
                disabled: needTemplate || chatbotActive,
              }"
              @click="
                needTemplate || chatbotActive
                  ? null
                  : actionButtons('showFastResponse'),
                  (fastResponsesOptions = []),
                  (fastResponsesNoCoincidences = false)
              "
            >
              <font-awesome-icon
                :icon="['fas', 'bolt']"
                style="margin-right: 10px"
              />
              Respuesta rápida
            </li>
            <li
              :class="{ active: showNote }"
              @click="actionButtons('showNote')"
            >
              <font-awesome-icon
                :icon="['fas', 'pen-to-square']"
                style="margin-right: 10px"
              />
              Añadir nota
            </li>
          </ul>
          <div v-if="showEmoji" class="emoji-picker">
            <VEmojiPicker @select="selectEmoji" />
          </div>
          <div v-if="showUploader" class="file-uploader">
            <table v-if="files.length + images.length < 5" border="0">
              <tr>
                <td>
                  <label for="image" class="message-type-button w-auto">
                    <input
                      id="image"
                      ref="image"
                      type="file"
                      hidden
                      style="display: none;"
                      accept="image/jpeg, image/png"
                      @change="addFile(false)"
                    />
                    Cargar imagen
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="file" class="message-type-button w-auto">
                    <input
                      id="file"
                      ref="file"
                      type="file"
                      hidden
                      accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .xls, .xlsx, .pdf"
                      style="display: none;"
                      @change="addFile(true)"
                    />
                    Cargar archivo
                  </label>
                </td>
              </tr>
            </table>

            <p v-else>
              Solo puedes enviar un máximo de 5 fotos y/o archivos de forma
              paralela
            </p>
          </div>
          <table class="w-100 messageView__input">
            <tr v-if="showFastResponse">
              <td class="w-100">
                <hr />
                <div class="d-flex">
                  <select
                    id="fastResponseSelect"
                    v-model="fastResponseTypeSelected"
                    class="form-control w-25"
                  >
                    <option
                      v-for="(type, key) in fastResponseTypes"
                      :key="'fast-response-type-' + key"
                      :value="type.value"
                    >
                      {{ type.text }}</option
                    >
                  </select>
                  <vue-bootstrap-typeahead
                    v-model="fastResponseQuery"
                    class="w-65 p-5px"
                    :data="fastResponsesOptions"
                    placeholder="Escribe algo aquí para buscar"
                    :min-matching-chars="0"
                    @keyup.delete="fastResponsesOptions = []"
                    @hit="setFastResponse()"
                  />
                  <div class="w-15">
                    <button
                      class="edit-quick-messages-button w-100"
                      @click="actionButtons('showEditQuickMessages')"
                    >
                      <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                    </button>
                  </div>
                </div>
                <div
                  v-if="
                    fastResponsesNoCoincidences && fastResponseQuery.length > 0
                  "
                  class="text-center"
                >
                  <p
                    class="m-0px fast-response-no-coincidences-message"
                    style="padding-top:15 px"
                  >
                    No se encuentran coincidencias con tu búsqueda.
                  </p>
                </div>
              </td>
            </tr>
            <tr v-if="chatbotActive && !privateOrPublicMessage">
              <td class="w-100">
                <hr />
                <div class="chatbot-info">
                  Conversación por <b>chatbot</b>. Desactivar abajo.
                </div>
              </td>
            </tr>
            <tr v-else-if="needTemplate && !privateOrPublicMessage">
              <td class="w-100">
                <hr />
                <div class="template-info" @click="showTemplates = true">
                  {{ buttonMessage }}
                </div>
              </td>
            </tr>
            <tr v-else>
              <td class="w-100">
                <hr />
                <textarea
                  v-model="content"
                  :class="privateOrPublicClass"
                  :placeholder="
                    privateOrPublicMessage
                      ? 'Escribe una nota'
                      : 'Escribe un mensaje'
                  "
                  rows="2"
                  @keydown.enter.prevent="sendMessage"
                ></textarea>
              </td>
            </tr>
            <tr>
              <table class="footer-action-btns">
                <tr>
                  <td style="text-align: left;">
                    <div
                      :style="{
                        visibility: haveChatbot ? 'visible' : 'hidden',
                      }"
                    >
                      <label
                        class="chatbox"
                        :class="{ disabled: !chatbotActive }"
                      >
                        <input
                          :checked="chatbotActive"
                          class="i-switch"
                          type="checkbox"
                          @change="chatbotToggle"/>
                        Chatbot {{ chatbotActive ? 'activo' : 'inactivo' }}
                        <img
                          v-if="chatbotActive"
                          src="http://s3.amazonaws.com/cdn.mobysuite.com/extras/virtual-avatar.png"
                      /></label>
                    </div>
                  </td>
                  <td width="1px">
                    <font-awesome-icon
                      v-if="!needTemplate || privateOrPublicMessage"
                      :class="{
                        messageView__footerIcon: true,
                        active: showEmoji,
                      }"
                      icon="fa-solid fa-face-laugh-beam"
                      @click="
                        showEmoji = !showEmoji
                        showUploader = false
                        showMenu = false
                      "
                    />
                  </td>
                  <td v-if="!needTemplate" width="1px">
                    <font-awesome-icon
                      :class="{
                        messageView__footerIcon: true,
                        active: showUploader,
                      }"
                      icon="fa-solid fa-paperclip"
                      @click="
                        showUploader = !showUploader
                        showEmoji = false
                        showMenu = false
                      "
                    />
                  </td>
                  <td v-if="false" width="1px">
                    <!--<vue-record-audio @result="onResult" mode="press" />-->
                  </td>
                  <td style="position: relative;">
                    <button
                      v-if="privateOrPublicMessage"
                      type="button"
                      class="messageView__footerBtn"
                      @click="sendMessage"
                    >
                      <span
                        ><font-awesome-icon :icon="['fas', 'paper-plane-top']"
                      /></span>
                      Enviar nota
                    </button>
                    <button
                      v-else-if="chatbotActive"
                      type="button"
                      :disabled="true"
                      class="messageView__footerBtn disabled"
                    >
                      <span
                        ><font-awesome-icon :icon="['fas', 'paper-plane-top']"
                      /></span>
                      Enviar {{ privateOrPublicMessage ? 'nota' : 'mensaje' }}
                    </button>
                    <button
                      v-else-if="needTemplate"
                      type="button"
                      class="messageView__footerBtn"
                      @click="showTemplates = true"
                    >
                      <span
                        ><font-awesome-icon :icon="['fas', 'paper-plane-top']"
                      /></span>
                      Enviar plantilla
                    </button>
                    <button
                      v-else
                      type="button"
                      class="messageView__footerBtn"
                      @click="sendMessage"
                    >
                      <span
                        ><font-awesome-icon :icon="['fas', 'paper-plane-top']"
                      /></span>
                      Enviar mensaje
                    </button>
                  </td>
                </tr>
              </table>
            </tr>
          </table>
        </div>
      </div>
      <vue-easy-lightbox
        move-disabled
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="visible = false"
      ></vue-easy-lightbox>
    </div>
    <div v-if="showTemplates">
      <transition name="modal">
        <div class="modal-mask" @click="closeTemplate()">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Selecciona la plantilla a enviar
                <button class="modal-default-button" @click="closeTemplate()">
                  &#x2715;
                </button>
              </div>
              <div class="modal-body">
                <template v-for="(template, key) in templates">
                  <div
                    v-if="template.body"
                    :key="`tempaltes-${key}`"
                    :class="{
                      'template-selector': true,
                      selected: selectedTemplate == template,
                    }"
                    @click="selectedTemplate = template"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M493.3 128l-22.6 22.6-256 256L192 429.3l-22.6-22.6-128-128L18.7 256 64 210.7l22.6 22.6L192 338.7 425.4 105.4 448 82.7 493.3 128z"
                      />
                    </svg>
                    {{ template.body }}
                  </div>
                </template>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-block btn-primary"
                  :disabled="!selectedTemplate"
                  @click="sendTemplate()"
                >
                  SELECCIONAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showContacts">
      <transition name="modal">
        <div class="modal-mask" @click="closeContact()">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                {{ contacts.length > 1 ? contacts.length : 'Ver ' }} contacto{{
                  contacts.length > 1 ? 's' : ''
                }}
                <button class="modal-default-button" @click="closeContact()">
                  &#x2715;
                </button>
              </div>
              <div class="modal-body">
                <div v-for="(contact, key) in contacts" :key="`contact-${key}`">
                  <div class="contact-name">
                    {{ JSON.parse(contact).name.formatted_name }}
                  </div>
                  <div class="contact-detail">
                    <table>
                      <tr
                        v-for="(phone, k) in JSON.parse(contact).phones"
                        :key="`contact-phone-${k}`"
                      >
                        <td>
                          <small>Teléfono</small>
                          {{ phone.phone }}
                        </td>
                      </tr>
                      <tr
                        v-for="(email, k) in JSON.parse(contact).emails"
                        :key="`contact-email-${k}`"
                      >
                        <td>
                          <small>Email</small>
                          {{ email.email }}
                        </td>
                      </tr>
                      <tr v-if="JSON.parse(contact).birthday">
                        <td>
                          <small>Fecha nacimiento</small>
                          {{
                            new Date(
                              JSON.parse(contact).birthday
                            ).toLocaleDateString('es-ES')
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showProgram">
      <transition v-if="!showProgrammedMessages" name="modal">
        <div class="modal-mask" @click="closeProgram()">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Programar mensaje
                <button class="modal-default-button" @click="closeProgram()">
                  &#x2715;
                </button>
              </div>
              <div class="modal-body">
                <p class="info">
                  Para poder programar un mensaje, debes seleccionar la fecha en
                  que enviarás el mensaje y que texto enviaras dentro de las
                  opciones disponibles.
                </p>
                <button
                  class="btn btn-block btn-primary"
                  @click="openOrCloseProgrammedMessages(true)"
                >
                  Ver tus mensajes programados
                </button>
                <table class="time-selection-table">
                  <tr>
                    <td class="text-left">
                      <p class="py-0">Fecha</p>
                      <date-picker
                        v-model="programDate"
                        style="width:90%"
                        :append-to-body="false"
                        format="DD-MM-YYYY"
                        :disabled-date="disablePastDates"
                        @change="onDateChange"
                      ></date-picker>
                    </td>
                    <td class="text-left" style="width:40%">
                      <p class="py-0">Hora</p>
                      <select
                        id="exampleFormControlSelect1"
                        v-model="programTime"
                        class="form-control"
                      >
                        <option
                          v-for="(hour, key) in availableHours"
                          :key="'hour-' + key"
                          >{{ hour }}</option
                        >
                      </select>
                    </td>
                  </tr>
                </table>

                <div class="d-flex justify-content-space">
                  <button
                    class="message-type-button"
                    :class="{ active: messageType === 'templates' }"
                    @click="messageType = 'templates'"
                  >
                    Plantillas
                  </button>
                  <button
                    :disabled="!canSendCustomMessage"
                    class="message-type-button"
                    :class="{ active: messageType === 'custom' }"
                    @click="messageType = 'custom'"
                  >
                    Personalizado
                  </button>
                </div>
                <div v-if="!canSendCustomMessage">
                  <small style="margin-top:5px;"
                    >(Sólo puedes enviar mensajes personalizados antes de 20
                    horas de emitido el último mensaje del cliente)</small
                  >
                </div>
                <div>
                  <div
                    v-if="canSendCustomMessage && messageType === 'custom'"
                    class="mt-20px"
                  >
                    <table
                      class="messageView__input d-flex"
                      style="padding:0px"
                    >
                      <td class="d-flex w-100" style="padding:0px">
                        <textarea
                          v-model="selectedCustomMessage"
                          class="custom-message-textarea"
                          :class="privateOrPublicClass"
                          placeholder="Escribe un mensaje"
                          rows="2"
                          maxlength="255"
                          @keydown.enter.prevent="sendMessage"
                        ></textarea>
                      </td>
                    </table>
                  </div>
                  <div v-else-if="messageType === 'templates'">
                    <template v-for="(template, key) in templates">
                      <div
                        v-if="template.body"
                        :key="`tempaltes-${key}`"
                        :class="{
                          'template-selector': true,
                          selected: selectedTemplate == template,
                        }"
                        @click="selectedTemplate = template"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M493.3 128l-22.6 22.6-256 256L192 429.3l-22.6-22.6-128-128L18.7 256 64 210.7l22.6 22.6L192 338.7 425.4 105.4 448 82.7 493.3 128z"
                          />
                        </svg>
                        {{ template.body }}
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div class="modal-footer">
                  <div class="d-flex">
                    <button
                      class="btn btn-block btn-primary"
                      :disabled="
                        (messageType === 'custom' && !selectedCustomMessage) ||
                          (messageType === 'templates' && !selectedTemplate) ||
                          !programDate ||
                          !programTime ||
                          (selectedCustomMessage &&
                            selectedCustomMessage.length > 255)
                      "
                      @click="sendProgram()"
                    >
                      Programar
                    </button>
                  </div>
                  <span
                    class="send-link-template"
                    style="font-size: 12px;"
                    @click="selectedTemplate ? sendTemplate() : null"
                  >
                    Enviar de inmediato
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition v-if="showProgrammedMessages" name="modal">
        <div class="modal-mask" @click="closeProgram()">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Mensajes programados
                <button class="modal-default-button" @click="closeProgram()">
                  &#x2715;
                </button>
              </div>
              <button
                class="btn btn-block btn-primary"
                style="margin-bottom: 20px;width:25%"
                @click="
                  openOrCloseProgrammedMessages(false),
                    (selectedScheduledMessage = null)
                "
              >
                Volver
              </button>
              <div class="modal-body">
                <p
                  v-if="!loadingSpinner && scheduledMessages.length > 0"
                  class="info"
                >
                  Una vez eliminado el mensaje programado,
                  <b>este no se podrá recuperar</b>
                </p>
                <div v-if="loadingSpinner" class="text-center">
                  <LoadingSpinner />
                </div>
                <div v-if="!loadingSpinner && scheduledMessages.length === 0">
                  <p class="text-center">No tienes mensajes programados</p>
                </div>
                <div v-else>
                  <template
                    v-for="(scheduledMessage, key) in scheduledMessages"
                  >
                    <div
                      v-if="scheduledMessage"
                      :key="`scheduledMessage-${key}`"
                      :class="{
                        'template-selector-delete': true,
                        selected: selectedScheduledMessage == scheduledMessage,
                      }"
                      @click="selectedScheduledMessage = scheduledMessage"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        width="100"
                        height="100"
                        viewBox="0 0 256 256"
                        xml:space="preserve"
                      >
                        <defs></defs>
                        <g
                          style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;"
                          transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
                        >
                          <path
                            d="M 13.4 88.492 L 1.508 76.6 c -2.011 -2.011 -2.011 -5.271 0 -7.282 L 69.318 1.508 c 2.011 -2.011 5.271 -2.011 7.282 0 L 88.492 13.4 c 2.011 2.011 2.011 5.271 0 7.282 L 20.682 88.492 C 18.671 90.503 15.411 90.503 13.4 88.492 z"
                            style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;"
                            transform=" matrix(1 0 0 1 0 0) "
                            stroke-linecap="round"
                          />
                          <path
                            d="M 69.318 88.492 L 1.508 20.682 c -2.011 -2.011 -2.011 -5.271 0 -7.282 L 13.4 1.508 c 2.011 -2.011 5.271 -2.011 7.282 0 l 67.809 67.809 c 2.011 2.011 2.011 5.271 0 7.282 L 76.6 88.492 C 74.589 90.503 71.329 90.503 69.318 88.492 z"
                            style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;"
                            transform=" matrix(1 0 0 1 0 0) "
                            stroke-linecap="round"
                          />
                        </g>
                      </svg>
                      <span class="text-bold">
                        {{
                          scheduledMessage.text === null
                            ? 'Plantilla -'
                            : 'Personalizado -'
                        }}
                        {{ parseScheduledDate(scheduledMessage.delivery_date) }}
                      </span>
                      <br />
                      {{
                        scheduledMessage.text === null
                          ? getTemplateMessage(scheduledMessage.template_id)
                          : scheduledMessage.text
                      }}
                    </div>
                  </template>
                </div>
              </div>
              <div class="modal-footer">
                <div class="modal-footer">
                  <div
                    v-if="selectedScheduledMessage && !loadingSpinner"
                    class="d-flex"
                  >
                    <button
                      class="btn btn-block btn-primary"
                      :disabled="selectedScheduledMessage === null"
                      @click="
                        ;(scheduledMessages = []),
                          deleteScheduledMessage(selectedScheduledMessage.id)
                      "
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showProgramResponse">
      <transition name="modal">
        <div class="modal-mask" @click="showProgramResponse = false">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Programar mensaje
                <button
                  class="modal-default-button"
                  @click="showProgramResponse = false"
                >
                  &#x2715;
                </button>
              </div>
              <div class="modal-body">
                <p>
                  Mensaje programado correctamente.
                </p>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-block btn-primary"
                  @click="showProgramResponse = false"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showScheduledMessageDeletedResponse">
      <transition name="modal">
        <div
          class="modal-mask"
          @click="showScheduledMessageDeletedResponse = false"
        >
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Mensajes programados
                <button
                  class="modal-default-button"
                  @click="showScheduledMessageDeletedResponse = false"
                >
                  &#x2715;
                </button>
              </div>
              <div class="modal-body">
                <p>
                  {{ scheduledMessageDeletedResponse }}
                </p>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-block btn-primary"
                  @click="showScheduledMessageDeletedResponse = false"
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="showEditQuickMessages">
      <transition
        v-if="
          !addingQuickMessage &&
            !editingQuickMessage &&
            !showQuickMessageSuccessfulAction
        "
        name="modal"
      >
        <div class="modal-mask" @click="showEditQuickMessages = false">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Editar respuestas rápidas personales
                <button
                  class="modal-default-button"
                  @click="showEditQuickMessages = false"
                >
                  &#x2715;
                </button>
              </div>
              <div class="modal-body quick-responses-body">
                <div v-if="loadingExecutiveQuickResponses">
                  <p class="text-center">
                    Cargando mensajes...
                  </p>
                </div>
                <div
                  v-if="
                    !loadingExecutiveQuickResponses && quickResponses.length > 0
                  "
                >
                  <div
                    v-for="(quickResponse, key) in quickResponses"
                    :key="'kick-response-' + key"
                    class="template-selector"
                    @click="editingQuickMessage = { ...quickResponse }"
                  >
                    {{ quickResponse.text }}
                  </div>
                </div>
                <div
                  v-else-if="
                    !loadingExecutiveQuickResponses &&
                      quickResponses.length <= 0
                  "
                >
                  <p class="text-center">
                    No tienes respuestas rápidas personales.
                  </p>
                </div>
              </div>
              <div class="modal-footer">
                <div class="pb-10px">
                  <button
                    class="btn btn-block btn-primary"
                    @click="addingQuickMessage = true"
                  >
                    Agregar
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-block btn-secondary"
                    @click="showEditQuickMessages = false"
                  >
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition
        v-else-if="
          (editingQuickMessage || addingQuickMessage) &&
            !showQuickMessageSuccessfulAction
        "
        name="modal"
      >
        <div class="modal-mask" @click="showEditQuickMessages = false">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Editar respuestas rápidas personales
                <button
                  class="modal-default-button"
                  @click="showEditQuickMessages = false"
                >
                  &#x2715;
                </button>
              </div>
              <div class="modal-body quick-responses-body">
                <table
                  v-if="editingQuickMessage"
                  class="messageView__input d-flex"
                  style="padding:0px"
                >
                  <td class="d-flex w-100" style="padding:0px">
                    <textarea
                      v-model="editingQuickMessage.text"
                      class="custom-message-textarea"
                      placeholder="Escribe una respuesta rápida"
                      rows="4"
                    ></textarea>
                  </td>
                </table>
                <table
                  v-else-if="addingQuickMessage"
                  class="messageView__input d-flex"
                  style="padding:0px"
                >
                  <td class="d-flex w-100" style="padding:0px">
                    <textarea
                      v-model="addingQuickMessageText"
                      class="custom-message-textarea"
                      placeholder="Escribe una respuesta rápida"
                      rows="4"
                    ></textarea>
                  </td>
                </table>
              </div>
              <div class="modal-footer">
                <div
                  v-if="editingQuickMessage"
                  class="d-flex justify-content-space pb-10px"
                >
                  <button
                    :disabled="editingQuickMessage.text === ''"
                    class="edit-type-button"
                    @click="editQuickMessage(editingQuickMessage)"
                  >
                    Editar
                  </button>
                  <button
                    class="delete-type-button"
                    @click="deleteQuickMessage(editingQuickMessage.id)"
                  >
                    Eliminar
                  </button>
                </div>
                <div v-else-if="addingQuickMessage" class="pb-10px">
                  <button
                    :disabled="addingQuickMessageText === ''"
                    class="btn btn-block btn-primary"
                    @click="addQuickMessage(addingQuickMessageText)"
                  >
                    Agregar
                  </button>
                </div>
                <button
                  class="btn btn-block btn-secondary"
                  @click="
                    ;(addingQuickMessage = false), (editingQuickMessage = null)
                  "
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition v-else-if="showQuickMessageSuccessfulAction" name="modal">
        <div class="modal-mask" @click="showEditQuickMessages = false">
          <div class="modal-wrapper">
            <div class="modal-container" @click.stop="">
              <div class="modal-header">
                Editar respuestas rápidas personales
                <button
                  class="modal-default-button"
                  @click="showEditQuickMessages = false"
                >
                  &#x2715;
                </button>
              </div>
              <div class="modal-body quick-responses-body text-center">
                <p>{{ quickMessageSuccessfulActionText }}</p>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-block btn-secondary"
                  @click="
                    loadExecutiveQuickResponses(),
                      (addingQuickMessage = false),
                      (editingQuickMessage = null),
                      (showQuickMessageSuccessfulAction = false),
                      (quickMessageSuccessfulActionText = null)
                  "
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import SideBarHeader from './sideBarHeader.vue'
import SideBar from './sideBar.vue'
import MessageViewBody from './messageViewBody.vue'
import LoadingSpinner from './LoadingSpinner.vue'
import RoomService from '@/services/RoomService'
import MessageService from '@/services/MessageService'
import TemplateService from '@/services/TemplateService'
import QuickResponsesService from '@/services/QuickResponsesService'
import ScheduleMessageService from '@/services/ScheduleMessageService'
import { VEmojiPicker } from 'v-emoji-picker'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/es/es'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

let AppContext = null
export default {
  name: '',
  components: {
    SideBarHeader,
    SideBar,
    MessageViewBody,
    VEmojiPicker,
    DatePicker,
    VueBootstrapTypeahead,
    LoadingSpinner,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    clientPhone: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    executivePhone: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    clientName: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    executiveId: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    customerId: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    clientId: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    projectId: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    executiveName: { default: null },
    // eslint-disable-next-line vue/require-prop-types
    darkModeParent: { default: false },
  },
  data: function() {
    return {
      activeIndex: 0,
      activeIndexotro: 0,
      content: '',
      rooms: null,
      templates: null,
      darkMode: false,
      showTemplates: false,
      needTemplate: true,
      audioURL: null,
      mediaRecorder: null,
      showEmoji: false,
      showUploader: false,
      showMenu: false,
      showEditQuickMessages: false,
      selectedTemplate: null,
      selectedCustomMessage: null,
      showContacts: false,
      chatbotActive: false,
      contacts: [],
      fastResponseTypes: [
        { value: 'All', text: 'Todos' },
        { value: 'User', text: 'Personales' },
        { value: 'Customer', text: 'Inmobiliaria' },
      ],
      fastResponseTypeSelected: 'All',
      templateSender: {
        room: {},
      },
      messages: [],
      imgs: '', // Img Url , string or Array of string
      visible: false,
      index: 0,
      privateOrPublicClass: '',
      privateOrPublicMessage: false,
      showNote: false,
      showProgram: false,
      showFastResponse: false,
      showProgrammedMessages: false,
      programDate: null,
      programTime: null,
      fullProgramDate: null,
      availableHours: null,
      showProgramResponse: false,
      canSendCustomMessage: false,
      messageType: 'templates',
      fastResponseQuery: '',
      fastResponsesOptions: [],
      fastResponsesTypingTimeout: null,
      fastResponsesNoCoincidences: false,
      quickResponses: [],
      editingQuickMessage: null,
      showQuickMessageSuccessfulAction: false,
      quickMessageSuccessfulActionText: null,
      loadingExecutiveQuickResponses: false,
      addingQuickMessage: false,
      addingQuickMessageText: '',
      showLoading: false,
      currentPage: 1,
      keepLoading: true,
      roomId: null,
      roomSlug: null,
      loadingSpinner: false,
      scheduledMessages: [],
      selectedScheduledMessage: null,
      scheduledMessageDeletedResponse: null,
      showScheduledMessageDeletedResponse: null,
      sessionTimeLeft: null,
      scheduleMessageButtonBlocked: true,
      images: [],
      files: [],
    }
  },
  channels: {
    MessageChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (!data.message.template_id) {
          let newMessage = {
            created_at: data.message.created_at,
            text: data.message.text,
            client_user: false,
            message_type: data.message.message_type,
            media_url: data.message.media_url,
            latitude: data.message.latitude,
            longitude: data.message.longitude,
            contacts: data.message.contacts,
            message_id: data.message.message_id,
            error_display: data.message.error_display,
          }
          if (AppContext.rooms.length > 1) {
            AppContext.rooms
              .find(room => room.slug == data.room.slug)
              .messages.last_messages.unshift(newMessage)
          } else {
            if (AppContext.activeIndexotro == data.room.slug) {
              AppContext.messages.unshift(newMessage)
              AppContext.goBottom()
            }
          }
          //if (document.visibilityState != 'visible')
          //this.$refs.audioPlayer.play()
          if (AppContext.activeIndexotro == data.room.slug)
            AppContext.needTemplate = AppContext.compareDates()
        }
      },
      disconnected() {},
    },
    MessageStatusChannel: {
      connected() {},
      rejected() {},
      received(data) {
        if (AppContext.roomSlug == data.room_slug) {
          let message = AppContext.messages.find(
            msg => msg.message_id == data.message_id
          )
          message = message ? message : AppContext.messages[0]
          message.state = data.state
        }
      },
      disconnected() {},
    },
  },
  computed: {
    sessionHoursRemaining() {
      let hours = Math.floor(this.sessionTimeLeft / 3600000)
      return hours
    },
    sessionMinutesRemaining() {
      let minutes = Math.floor((this.sessionTimeLeft % 3600000) / 60000)
      return minutes
    },
    buttonMessage() {
      if (this.messages) {
        let lastUserDate = this.messages.filter(lm => lm.client_user).at(-1)
        if (lastUserDate) {
          let formatedLastUserDate = new Date(lastUserDate.created_at)
          formatedLastUserDate.setDate(formatedLastUserDate.getDate() + 1)
          if (new Date() < formatedLastUserDate) {
            return 'Para poder escribir el cliente debe responderte. Selecciona otra plantilla aquí.'
          }
        }
        return 'Para retomar la conversa debes enviar una plantilla. Selecciona una aquí.'
      } else {
        return 'Para iniciar tu conversa debes enviar una plantilla. Selecciona una aquí.'
      }
    },
    haveChatbot() {
      /* ALERTA:::: Valida que el producto exista en GC es necesario llamar a un endpoint, mientras es un array de clientes */
      if (this.rooms[this.activeIndex])
        return this.rooms[this.activeIndex].have_chatbot
      else return false
    },
  },
  watch: {
    messages: function() {
      this.setSessionTime()
      this.programDate = new Date()
      this.getAvailableHours()
      this.checkIfCanSendCustomMessage()
    },
    privateOrPublicMessage: function() {
      if (this.privateOrPublicMessage)
        this.privateOrPublicClass = 'messageView__private'
    },
    programTime: function(newValue) {
      if (newValue) {
        this.checkIfCanSendCustomMessage()
      }
    },
    fastResponseQuery: function(newValue) {
      if (newValue) {
        this.loadFastResponses(
          this.executiveId,
          this.customerId,
          this.fastResponseQuery,
          500
        )
      }
    },
    fastResponseTypeSelected: function(newValue) {
      if (newValue) {
        this.loadFastResponses(
          this.executiveId,
          this.customerId,
          this.fastResponseQuery,
          1
        )
      }
    },
  },
  created() {
    AppContext = this
    let self = this
    this.getRooms()
    this.getTemplates()
    this.$cable.subscribe(
      {
        channel: 'MessageChannel',
      },
      'MessageChannel'
    )
    this.$cable.subscribe(
      {
        channel: 'MessageStatusChannel',
      },
      'MessageStatusChannel'
    )
    self.darkMode = self.darkModeParent
  },
  mounted() {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    setInterval(() => {
      if (this.sessionTimeLeft > 0) {
        this.sessionTimeLeft = this.sessionTimeLeft - 1000 // Restar 1 segundo al contador
      }
    }, 1000) // 1000 milisegundos = 1 segundo
  },
  beforeDestroy() {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
  },
  methods: {
    handleScroll() {
      this.getMessages()
    },
    disablePastDates(date) {
      const today = new Date()
      const yesterday = today.setDate(today.getDate() - 1)
      return date < yesterday
    },
    getAvailableHours() {
      const date = this.programDate ? this.programDate : new Date() // Obtener la fecha seleccionada
      const today = new Date() // Obtener la fecha y hora actual

      // Verificar si la fecha seleccionada es hoy
      const isToday = date.toDateString() === today.toDateString()

      const hours = []
      let hour = 0
      let minute = 0

      // Si es hoy, obtener solo las horas desde este momento en adelante
      if (isToday) {
        hour = today.getHours()
        minute = today.getMinutes()

        // Redondear los minutos al siguiente múltiplo de 30
        const resto = minute % 30
        minute += resto === 0 ? 0 : 30 - resto
      }

      // Generar todas las horas con intervalos de 30 minutos
      for (; hour < 24; hour++) {
        for (; minute < 60; minute += 30) {
          const formattedHour = hour.toString().padStart(2, '0')
          const formattedMinute = minute.toString().padStart(2, '0')
          hours.push(`${formattedHour}:${formattedMinute}`)
        }
        minute = 0 // Reiniciar los minutos para las siguientes horas
      }

      this.availableHours = hours
      if (isToday) {
        this.programTime = hours[0]
      }
    },
    checkIfCanSendCustomMessage() {
      // Restar las fechas y obtener la diferencia en milisegundos
      const messages = JSON.parse(JSON.stringify(this.messages))
      if (messages.length > 0) {
        let messageUser = messages.find(message => message.client_user == false)
        if (messageUser) {
          this.scheduleMessageButtonBlocked = false
          const lastMessageDate = messageUser.created_at
          this.roomId = parseInt(
            messages.find(message => message.client_user == false).room_id
          )
          const [programTimeHours, programTimeMinutes] = this.programTime.split(
            ':'
          )
          const date = this.programDate ? this.programDate : new Date()
          date.setHours(programTimeHours)
          date.setMinutes(programTimeMinutes)

          const difference = Math.abs(
            date -
              new Date(
                lastMessageDate.toLocaleString('es-CL', { timeStyle: 'short' })
              )
          )
          // Convertir la diferencia de milisegundos a horas
          const hours = difference / (1000 * 60 * 60)
          this.fullProgramDate = this.getParsedFullProgramDate(this.programDate)
          // Verificar si la diferencia es menor o igual a 20 horas
          if (hours <= 20) {
            this.canSendCustomMessage = true
          } else {
            this.canSendCustomMessage = false
            this.messageType = 'templates'
          }
        } else {
          this.canSendCustomMessage = false
          this.scheduleMessageButtonBlocked = true
          this.messageType = 'templates'
        }
      } else {
        this.canSendCustomMessage = false
        this.scheduleMessageButtonBlocked = true
        this.messageType = 'templates'
      }
    },
    setSessionTime() {
      const date = new Date()

      // Restar las fechas y obtener la diferencia en milisegundos
      const messages = JSON.parse(JSON.stringify(this.messages))
      if (messages.length > 0) {
        let messageUser = messages.find(message => message.client_user == false)
        if (messageUser) {
          const lastMessageDate = messageUser.created_at
          this.roomId = parseInt(
            messages.find(message => message.client_user == false).room_id
          )

          const parsedLastMessageDate = new Date(
            lastMessageDate.toLocaleString('es-CL', { timeStyle: 'short' })
          )

          // Agregar 1 día a la fecha
          const tomorrowLastMessageDate = parsedLastMessageDate.setDate(
            parsedLastMessageDate.getDate() + 1
          )
          const difference = Math.abs(date - tomorrowLastMessageDate)

          // Convertir la diferencia de milisegundos a horas
          this.sessionTimeLeft = difference - 3600000 * 3
        }
      }
    },
    getParsedFullProgramDate(programDate) {
      var date = new Date(programDate)

      var year = date.getFullYear()
      var month = (date.getMonth() + 1).toString().padStart(2, '0')
      var day = date
        .getDate()
        .toString()
        .padStart(2, '0')
      var hour = date
        .getHours()
        .toString()
        .padStart(2, '0')
      var minute = date
        .getMinutes()
        .toString()
        .padStart(2, '0')
      var second = date
        .getSeconds()
        .toString()
        .padStart(2, '0')

      const parsedProgramDate = `${year}-${month}-${day}T${hour}:${minute}:${second}`
      return parsedProgramDate
    },
    setFastResponse() {
      this.content = this.fastResponseQuery
      this.fastResponseQuery = ''
      this.showFastResponse = false
    },
    actionButtons(buttonElement) {
      this.privateOrPublicMessage = false
      switch (buttonElement) {
        case 'showNote':
          this.showProgram = false
          this.showFastResponse = false
          this.showEditQuickMessages = false
          this.privateOrPublicMessage = !this.showNote
          this.showNote = !this.showNote

          break
        case 'showProgram':
          this.showNote = false
          this.showFastResponse = false
          this.showEditQuickMessages = false
          this.programDate = new Date()
          this.getAvailableHours()
          this.checkIfCanSendCustomMessage()
          this.showProgram = !this.showProgram
          break
        case 'showFastResponse':
          this.showNote = false
          this.showProgram = false
          this.showEditQuickMessages = false
          this.showFastResponse = !this.showFastResponse
          break
        case 'showEditQuickMessages':
          this.showProgram = false
          this.showFastResponse = false
          this.showNote = false
          this.showEditQuickMessages = !this.showEditQuickMessages
          this.loadExecutiveQuickResponses()
          break
      }
    },
    onDateChange() {
      this.getAvailableHours()
      this.checkIfCanSendCustomMessage()
    },
    showImage(url) {
      this.imgs = url
      this.visible = true
    },
    showContactModal(contacts) {
      this.contacts = contacts
      this.showContacts = true
    },
    sendMessage() {
      this.images.forEach(image => {
        RoomService.sendMessage(
          this.rooms[this.activeIndex].slug,
          image,
          this.executiveName,
          this.executiveId,
          this.privateOrPublicMessage,
          'image'
        ).then(res => {
          this.addMessage(
            '',
            new Date().toISOString().replace('T', ' '),
            true,
            res.body.message_id,
            res.body.message_type,
            res.body.media_url
          )
        })
      })
      this.images = []
      this.files.forEach(file => {
        RoomService.sendMessage(
          this.rooms[this.activeIndex].slug,
          file,
          this.executiveName,
          this.executiveId,
          this.privateOrPublicMessage,
          'document'
        ).then(res => {
          this.addMessage(
            '',
            new Date().toISOString().replace('T', ' '),
            true,
            res.body.message_id,
            res.body.message_type,
            res.body.media_url
          )
        })
      })
      this.files = []
      if (this.content != '') {
        RoomService.sendMessage(
          this.rooms[this.activeIndex].slug,
          this.content,
          this.executiveName,
          this.executiveId,
          this.privateOrPublicMessage,
          'text'
        ).then(res => {
          this.addMessage(
            this.content,
            new Date().toISOString().replace('T', ' '),
            true,
            res.body.message_id,
            null,
            null
          )
          this.content = ''
        })
      }
    },
    async getRooms() {
      let self = this
      this.showLoading = true
      RoomService.getRooms(
        this.clientPhone,
        this.customerId,
        this.projectId,
        this.clientId,
        this.clientName
      ).then(response => {
        self.rooms = response.body
        if (response.body[this.activeIndex]) {
          self.messages = response.body[this.activeIndex].messages.last_messages
          self.roomSlug = response.body[this.activeIndex].slug
          self.activeIndexotro = response.body[this.activeIndex].slug
          self.needTemplate = self.compareDates()
          self.showLoading = false
          self.chatbotActive = response.body[this.activeIndex].chatbot_active
        }
      })
    },
    async getMessages() {
      if (!this.keepLoading) return false
      let self = this
      this.showLoading = true
      this.currentPage += 1
      self.keepLoading = false
      MessageService.getMessages(this.activeIndexotro, this.currentPage).then(
        response => {
          self.messages = self.messages.concat(response.body.data)
          self.showLoading = false
          self.keepLoading = response.next_page_token == null ? false : true
        }
      )
    },
    getTemplates() {
      let self = this
      TemplateService.getTemplates(this.customerId, this.projectId).then(
        response => {
          self.templates = response.body.data
        }
      )
    },
    addMessage(
      text,
      created_at,
      client_user,
      message_id,
      message_type,
      media_url
    ) {
      let new_message = {
        created_at: created_at,
        text: text,
        client_user: client_user,
        state: 'sent',
        user_name: this.executiveName,
        user_id: this.executiveId,
        private: this.privateOrPublicMessage,
        message_id: message_id,
        message_type: message_type,
        media_url: media_url,
      }
      this.messages.unshift(new_message)
      this.goBottom()
      return new_message
    },
    onResult(data) {
      this.audioURL = window.URL.createObjectURL(data)
    },
    selectEmoji(emoji) {
      this.content += emoji.data
      this.showEmoji = false
    },
    compareDates() {
      if (this.messages) {
        if (this.messages.length > 0) {
          let lastClient = this.messages.filter(lm => !lm.client_user).at(0)
          if (lastClient) {
            let lastDateClient = new Date(lastClient.created_at)
            lastDateClient.setDate(lastDateClient.getDate() + 1)
            return new Date() >= lastDateClient
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    },
    goBottom() {
      if (document.querySelector('.whatsapp-data-message')) {
        document.querySelector('.whatsapp-data-message').scrollTo(0, 0)
      }
    },
    sendProgram() {
      const text =
        this.messageType === 'custom' ? this.selectedCustomMessage : undefined

      const templateId =
        this.messageType === 'templates' ? this.selectedTemplate.id : undefined

      ScheduleMessageService.scheduleMessage(
        this.clientId,
        templateId ? templateId : undefined,
        text ? text : undefined,
        this.fullProgramDate,
        this.roomId,
        this.executiveName
      )
        .then(() => {
          this.resetScheduledMessageOptions()
          this.showProgramResponse = true
        })
        .catch(error => {
          console.log(error)
        })
    },
    sendTemplate() {
      let self = this
      this.setTemplate(this.selectedTemplate)
      TemplateService.sendTemplate(this.templateSender).then(response => {
        self.rooms = [response.body]
        let new_message = {
          created_at: new Date().toISOString().replace('T', ' '),
          text: this.selectedTemplate.body,
          client_user: true,
          user_name: this.executiveName,
          state: 'sent',
          user_id: this.executiveId,
          message_id: response.body.messages.last_messages[0].message_id,
        }
        this.content = ''
        if (this.messages) {
          this.messages.unshift(new_message)
          this.goBottom()
          return new_message
        } else {
          return true
        }
      })
      this.showTemplates = false
      this.showProgram = false
    },
    setTemplate(template) {
      this.templateSender.room.template_id = template.id
      this.templateSender.room.client_phone = this.clientPhone
      this.templateSender.room.user_id = this.executiveId
      this.templateSender.room.customer_id = this.customerId
      this.templateSender.room.client_id = this.clientId
      this.templateSender.room.project_id = this.projectId
      this.templateSender.room.user_name = this.executiveName
      this.templateSender.room.user_id = this.executiveId
    },
    setButtonMessage() {
      if (this.messages) {
        let lastUserDate = this.messages.filter(lm => lm.client_user).at(0)
        if (lastUserDate) {
          let formatedLastUserDate = new Date(lastUserDate.created_at)
          formatedLastUserDate.setDate(formatedLastUserDate.getDate() + 1)
          if (new Date() >= formatedLastUserDate) {
            return 'Para poder escribir el cliente debe responderte. Selecciona otra plantilla aquí.'
          }
        }
        return 'Para retomar la conversa debes enviar una plantilla. Selecciona una aquí.'
      } else {
        return 'Para iniciar tu conversa debes enviar una plantilla. Selecciona una aquí.'
      }
    },
    closeTemplate() {
      this.selectedTemplate = null
      this.showTemplates = false
    },
    changeRoom(index) {
      this.activeIndex = index
      this.needTemplate = this.compareDates()
    },
    showExtraMenu() {
      this.showEmoji = false
      this.showUploader = false
      this.showMenu = !this.showMenu
      return true
    },
    closeContact() {
      this.showContacts = false
    },
    closeProgram() {
      this.showProgram = false
      this.selectedTemplate = null
      this.programDate = null
      this.programTime = null
    },
    openOrCloseProgrammedMessages(action) {
      action === true
        ? this.getScheduledMessages()
        : (this.showProgrammedMessages = false)
    },
    loadFastResponses(user_id, customer_id, message, timeoutTime = 500) {
      let self = this
      this.fastResponsesNoCoincidences = false
      clearTimeout(this.fastResponsesTypingTimeout)

      if (message.length > 0) {
        this.fastResponsesTypingTimeout = setTimeout(() => {
          switch (this.fastResponseTypeSelected) {
            case 'All':
              QuickResponsesService.getAllMessages(
                user_id,
                customer_id,
                message
              ).then(response => {
                response.body.data.length > 0
                  ? (self.fastResponsesOptions = response.body.data.map(
                      response => response.text
                    ))
                  : ((self.fastResponsesOptions = []),
                    (self.fastResponsesNoCoincidences = true))
              })
              break
            case 'User':
              QuickResponsesService.getExecutiveMessages(user_id, message).then(
                response => {
                  response.body.data.length > 0
                    ? (self.fastResponsesOptions = response.body.data.map(
                        response => response.text
                      ))
                    : ((self.fastResponsesOptions = []),
                      (self.fastResponsesNoCoincidences = true))
                }
              )
              break
            case 'Customer':
              QuickResponsesService.getCustomerMessages(
                customer_id,
                message
              ).then(response => {
                response.body.data.length > 0
                  ? (self.fastResponsesOptions = response.body.data.map(
                      response => response.text
                    ))
                  : ((self.fastResponsesOptions = []),
                    (self.fastResponsesNoCoincidences = true))
              })
              break
          }
        }, timeoutTime)
      }
    },
    async loadExecutiveQuickResponses() {
      this.loadingExecutiveQuickResponses = true
      try {
        let response = await QuickResponsesService.getExecutiveMessages(
          this.executiveId
        )
        this.quickResponses = response.body.data
        this.loadingExecutiveQuickResponses = false
      } catch (error) {
        console.log(error)
        this.loadingExecutiveQuickResponses = false
      }
    },
    async addQuickMessage(message) {
      try {
        await QuickResponsesService.addMessage(
          this.executiveId,
          this.customerId,
          message
        )
        this.showQuickMessageSuccessfulAction = true
        this.quickMessageSuccessfulActionText = 'Mensaje agregado correctamente'
        this.addingQuickMessageText = ''
      } catch (error) {
        console.log(error)
      }
    },
    async editQuickMessage(message) {
      try {
        await QuickResponsesService.editMessage(
          message.id,
          this.executiveId,
          this.customerId,
          message.text
        )
        this.showQuickMessageSuccessfulAction = true
        this.quickMessageSuccessfulActionText = 'Mensaje editado correctamente'
      } catch (error) {
        console.log(error)
      }
    },
    async deleteQuickMessage(messageId) {
      try {
        await QuickResponsesService.deleteMessage(messageId)
        this.showQuickMessageSuccessfulAction = true
        this.quickMessageSuccessfulActionText =
          'Mensaje eliminado correctamente'
      } catch (error) {
        console.log(error)
      }
    },
    async getScheduledMessages() {
      this.scheduledMessages = []
      this.showProgrammedMessages = true
      try {
        this.loadingSpinner = true
        this.scheduledMessages = await ScheduleMessageService.getScheduledMessages(
          this.roomId
        )
        this.loadingSpinner = false
      } catch (error) {
        console.log(error)
      }
    },
    async deleteScheduledMessage(scheduledMessageId) {
      try {
        this.selectedScheduledMessage = null
        this.loadingSpinner = true
        await ScheduleMessageService.deleteScheduledMessage(scheduledMessageId)
        this.resetScheduledMessageOptions()
        this.showScheduledMessageDeletedResponse = true
        this.scheduledMessageDeletedResponse =
          'Mensaje programado eliminado correctamente.'
        this.loadingSpinner = false
      } catch (error) {
        this.resetScheduledMessageOptions()
        this.showScheduledMessageDeletedResponse = true
        this.scheduledMessageDeletedResponse =
          'Ocurrió un error eliminando el mensaje, intentalo de nuevo más tarde.'
        console.log(error)
      }
    },
    resetScheduledMessageOptions() {
      this.selectedScheduledMessage = null
      this.programDate = null
      this.programTime = null
      this.showProgram = false
      this.showProgramResponse = false
      this.showProgrammedMessages = false
      this.selectedScheduledMessage = null
      this.selectedTemplate = null
      this.selectedCustomMessage = null
      this.messageType = 'templates'
      this.showScheduledMessageDeletedResponse = null
      this.programDate = new Date()
      this.getAvailableHours()
      this.checkIfCanSendCustomMessage()
    },
    parseScheduledDate(scheduledDate) {
      const dateObject = new Date(scheduledDate)
      return `${dateObject.getUTCDate()}/${dateObject.getUTCMonth() +
        1}/${dateObject.getUTCFullYear()} a las ${String(
        dateObject.getUTCHours()
      ).padStart(2, '0')}:${String(dateObject.getUTCMinutes()).padStart(
        2,
        '0'
      )}hrs`
    },
    getTemplateMessage(templateId) {
      return this.templates.find(template => template.id === templateId).body
    },
    chatbotToggle() {
      if (this.haveChatbot) {
        this.chatbotActive = !this.chatbotActive
        this.rooms[this.activeIndex].chatbot_active = this.chatbotActive
        RoomService.toggleChatbot(
          this.rooms[this.activeIndex].slug,
          this.chatbotActive
        ).then(response => {
          console.log(response)
        })
      }
    },
    addFile(fileOrImage) {
      if (this.files.length + this.images.length >= 5) {
        console.log('No puedes subir más de 5 archivos')
        return
      } else {
        if (fileOrImage) {
          this.files.push(this.$refs.file.files[0])
        } else {
          this.images.push(this.$refs.image.files[0])
        }
        this.showUploader = false
      }
    },
    setImage(image) {
      return URL.createObjectURL(image)
    },
    removeFile(fileOrImage, index) {
      if (fileOrImage) {
        this.files.splice(index, 1)
      } else {
        this.images.splice(index, 1)
      }
    },
  },
}
window.addEventListener('load', () => {
  document.addEventListener('set-dark-mode', setDarkMode, false)
})

function setDarkMode(e) {
  if (e) {
    AppContext.darkMode = e.detail.darkMode
  }
}
</script>
