<template>
  <WhatsappComponent
    :client-phone="clientPhone"
    :executive-name="executiveName"
    :executive-phone="executivePhone"
    :client-name="clientName"
    :executive-id="executiveId"
    :customer-id="customerId"
    :client-id="clientId"
    :project-id="projectId"
    :dark-mode-parent="darkMode"
  />
</template>
<script>
import WhatsappComponent from './components/WhatsappComponent.vue'
export default {
  name: 'App',
  components: {
    WhatsappComponent,
  },
  props: {
    clientPhone: { type: Number, default: null },
    executivePhone: { type: Number, default: null },
    clientName: { type: String, default: null },
    executiveId: { type: Number, default: null },
    customerId: { type: Number, default: null },
    clientId: { type: Number, default: null },
    projectId: { type: Number, default: null },
    executiveName: { type: String, default: null },
    darkMode: { type: Boolean, default: false },
  },
}
</script>
<style lang="scss">
@import 'assets/sass/style';
</style>
