<template>
  <div>
    <div v-if="compareDates()" class="date">
      <span>{{
        `${new Date(time).getDate()}/${new Date(time).getMonth() +
          1}/${new Date(time).getFullYear()}`
      }}</span>
    </div>
    <div style="clear: both; position: relative;">
      <template v-if="send && !message.private">
        <div v-show="showEx" class="executive-popover">
          {{ executive.user_name }}
        </div>
        <div
          v-if="executive.user_avatar"
          :style="{
            backgroundImage: 'url(' + executive.user_avatar + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }"
          class="executive"
          @mouseenter="showEx = true"
          @mouseout="showEx = false"
        ></div>
        <div
          v-else
          class="executive"
          @mouseenter="showEx = true"
          @mouseout="showEx = false"
        >
          {{ setInitials(executive.user_name) }}
        </div>
      </template>
      <message-box
        :message="message"
        :send="send"
        :time="time"
        @show-image="showImage"
        @show-contacts-modal="showContactsModal"
      />
    </div>
  </div>
</template>
<script>
import MessageBox from './MessageBox.vue'
export default {
  name: 'MessageViewBody',
  components: {
    MessageBox,
  },
  props: {
    message: {
      type: Object,
      default: () => {
        return {}
      },
    },
    time: { type: String, default: null },
    nextTime: { type: String, default: null },
    send: { type: Boolean, default: null },
  },
  data() {
    return {
      icon: 'fa-check fa-check',
      class: '',
      showEx: false,
    }
  },
  computed: {
    getIcon() {
      switch (this.message.state) {
        case 'failed':
          return 'fa-solid fa-circle-exclamation'
        case 'sent':
          return 'fa-solid fa-check'
        case 'delivered':
          return 'fa-solid fa-check-double'
        case 'read':
          return 'fa-solid fa-check-double'
      }
      return null
    },
    getClass() {
      switch (this.message.state) {
        case 'failed':
          return 'status-failed'
        case 'read':
          return 'status-read'
        default:
          return 'status'
      }
    },
    executive() {
      let response = {}
      if (this.message.user_name) {
        response.user_name = this.message.user_name
        if (this.message.user_avatar) {
          response.user_avatar = this.message.user_avatar
        }
      } else {
        response = {
          user_name: 'Ejecutivo Virtual',
          user_avatar:
            'http://s3.amazonaws.com/cdn.mobysuite.com/extras/virtual-avatar.png',
        }
      }
      return response
    },
  },
  mounted() {
    document
      .querySelector('.whatsapp-data-message')
      .addEventListener('scroll', this.handleScroll)
  },
  destroyed() {
    document
      .querySelector('.whatsapp-data-message')
      .removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    compareDates() {
      let nextTime = `${new Date(this.nextTime).getDate()}/${new Date(
        this.nextTime
      ).getMonth() + 1}/${new Date(this.nextTime).getFullYear()}`
      let time = `${new Date(this.time).getDate()}/${new Date(
        this.time
      ).getMonth() + 1}/${new Date(this.time).getFullYear()}`
      return !(nextTime == time)
    },
    showImage(url) {
      this.$emit('show-image', url)
    },
    setInitials(user) {
      if (user != undefined) {
        let name = user.split(' ')
        return name.length > 1 ? `${name[0][0]}${name[1][0]}` : name[0][0]
      }
    },
    showContactsModal(contacts) {
      this.$emit('show-contacts-modal', contacts)
    },
    handleScroll() {
      const scrollVal = document.querySelector('.whatsapp-data-message')
      if (
        scrollVal.scrollHeight + scrollVal.scrollTop <
        scrollVal.offsetHeight + 25
      )
        this.$emit('handle-scroll', scrollVal)
    },
  },
}
</script>
<style></style>
